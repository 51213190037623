/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/composite/Layout';
import Seo from '../components/simple/seo';
import WPPagePage from '../components/pages/WPPagePage';
import parseLayoutProps from '../templates/common/parse/parseLayoutProps';

import {statusPageBody, statusPageContainer} from '../pages-assets/statusPage.module.scss';
import {testAttributes} from '../pages-assets/Constants'

import {
    siteMetadata,
    menuNodes,
    menuItemNodes,
} from '../utilities/fragments';


const NotFoundPage = ({ content }) => 
    (
        <WPPagePage 
            postInfo={{infoPost: { content }}} 
            showTitle={false} 
            fullContainer
            gridTitle={null}
            showBreadCrumbs={false}
            showPostGrid={false}
        />
    );
NotFoundPage.propTypes = {
    content: PropTypes.element.isRequired
}
export { NotFoundPage }

const page404Content = <div className={statusPageContainer} data-test={testAttributes.STATUS_PAGE_CONTAINER}>
    <h1>404</h1>
    <h2>Page not found.</h2>
    <p>The page you requested cannot be found.</p>
</div>
export {page404Content}

const NotFoundContainer = ({data,location}) => 
{
    const { footerProps, headerProps } = parseLayoutProps(data, location);
    return (
        <Layout 
            headerProps={headerProps}
            footerProps={footerProps}
        >
            <Seo 
                helmetProps={{title: '404: not found'}}
                bodyClass={statusPageBody}
            />
            <NotFoundPage 
                content={page404Content}
            />
        </Layout>
    );
}

NotFoundContainer.propTypes = {
    location: PropTypes.string.isRequired,
    data: PropTypes.shape({
        site: PropTypes.instanceOf(Object),
    }).isRequired,
};

export default NotFoundContainer;

export const pageQuery = graphql`
    query {
        site {
            ...siteMetadata
        }
        headerMenuNav: allWpMenuItem(
            filter: { locations: { eq: MENU_1 }, parentDatabaseId: { eq: 0 } }
        ) {
            ...menuItemNodes
        }
        footerMenuPolicy: wpMenu(locations: { eq: MENU_2 }) {
            menuItems {
                ...menuNodes
            }
        }
        footerMenuCol1: wpMenu(locations: { eq: FOOTER_COL_1 }) {
            menuItems {
                ...menuNodes
            }
        }
        footerMenuCol2: wpMenu(locations: { eq: FOOTER_COL_2 }) {
            menuItems {
                ...menuNodes
            }
        }
    }
`;
